:root {
  --max-width: 1100px;
  --border-radius: 12px;
  //--gray-900: #101828 !important;
  //--gray-700: #344054 !important;
  //--gray-600: #475467 !important;
  //--blue-600: #0086C9 !important;
}

html,
body {
  max-width: 100vw;
}

html {
  height: 100vh;

  font-size: 14px;

  &[light] {
    body {
      //background-color: var(--gray-900);
      background-color: var(--surface-ground);
    }
  }

  &[dark] {
    body {
      background-color: var(--gray-800);
    }
  }
}

body {
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style-type: none;
}

.p-progressbar-label {
  display: none !important;
}

.p-sidebar.vh55 {
  height:55vh !important;
}

.p-datatable {
  a:hover {
    text-decoration: underline;
  }
}

.cursor-help {
  cursor: help;
}

.border-x-light {
  border-color: rgba(0, 0, 0, 0.04);
}

.bg-warning {
  color: #ffffff;
  background: #F59E0B;
  border: 1px solid #F59E0B;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd {
  background: var(--surface-border);
}

html[wide] {
  body {
    .ctc {
      max-width: unset;
    }
  }
}

body {
  .mlt {
    display: flex;
    justify-content: center;
    gap: 8px;

    @media screen and (max-width: 1440px) {
      padding: 0 0;
      justify-content: flex-start;
    }

    padding: 0 32px;

    .ltc {
       min-height: 98vh;
    }

    .ctc {
      width: 100%;
      max-width: 1700px;
      min-width: 0;

      position: relative;
      display: flex;
      flex-direction: column;
    }

    .rtc {

    }
  }

  .no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

.p-datatable-header,
.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-rowgroup-header {
  background-color: var(--surface-ground) !important;
}

.p-contextmenu {
  font-size: .8rem;
  margin-top: 16px;
}

.p-menuitem.disabled {
  pointer-events: none;
}
