.sidebar_mobile {
  :global {
    .p-sidebar-content {
      margin-top: -60px;
    }
  }
}

.sidebar {
  .link {
    display: flex;
    align-items: center;
    gap: 4px;
    width: 100%;
    background-color: transparent;
    border: 0;
    color: var(--text-color-secondary);

    font-weight: 500;

    min-height: 42px;
    border-radius: 8px;

    &.checked {
      color: var(--text-color-secondary);
      background-color: var(--surface-hover);
    }

    &:hover {
      color: var(--text-color-secondary);
      background-color: var(--surface-hover);
    }

    &.ii {
      font-weight: 300;
    }

    &.iii {
      font-weight: 100;
    }

    &.iv {
      &:hover {
        color: var(--text-color-secondary);
        background-color: var(--surface-hover);
      }
    }
  }

  :global {
    .p-panelmenu-content {
      background-color: transparent;
      color: var(--text-color-secondary);
      border: 0;
    }
  }
}

.appDesktopSideBar {
  @media screen and (min-width: 1200px) {
    top: 8px;
  }
}

.appMobileSideBar {
}

html[wide] {
  .appDesktopSideBar {
    display: none !important;
  }

  .appMobileSideBar {
    display: block !important;

    .mobileSideBarToggleButton {
    }

    @media screen and (max-width: 1440px) {
      .mobileSideBarToggleButton {
        margin-left: 0 !important;
      }
    }
  }
}
